import React from "react"
import PropTypes from "prop-types"
import { Icon } from "utils/helpers/getIcon"
import moment from "moment"
import AvenueImg from "../AvenueImg"

import {
  TeaserContainer,
  ImageContainer,
  InfoContainer,
  WrappingLink,
  Title,
  Excerpt,
  Date,
  NewButton,
  Tag,
} from "./style.js"

/*
catsTopRight
row
centered
*/

const Teaser = ({
  title,
  categories,
  excerpt,
  date,
  img,
  catsTopRight,
  row,
  tag,
  centered,
  overlapped,
  withButton,
  btnLabel,
  link,
  className,
  iconName,
  internalLink,
  titleLink,
  buttonLink,
}) => {
  const btnIcon = <Icon name={iconName} />

  return (
    <TeaserContainer className={className} row={row} centered={centered}>
      <ImageContainer row={row}>
        <AvenueImg fluid={img} />
      </ImageContainer>
      {internalLink && <WrappingLink to={link || "/"} />}

      <InfoContainer centered={centered} row={row} overlapped={overlapped}>
        {title && titleLink ? (
          <WrappingLink to={titleLink || "/"}>
            <Title
              centered={centered}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </WrappingLink>
        ) : (
          <Title
            centered={centered}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        {date && <Date> {moment(date).format("DD MMM YYYY")}</Date>}
        {tag && <Tag> {tag}</Tag>}

        {/* {categories && (
          <Categories catsTopRight={catsTopRight} categories={categories} />
        )} */}
        {excerpt && (
          <Excerpt
            centered={centered}
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />
        )}
        {withButton && (
          <NewButton label={btnLabel} link={buttonLink} icon={btnIcon} />
        )}
      </InfoContainer>
    </TeaserContainer>
  )
}

export default Teaser

Teaser.defaultProps = {
  title: "I'm a re-usable teaser",
  // date: "1995-02-17T10:11:12",
  // categories: ["art", "design", "food"],
  // excerpt:
  //   "Here’s an introduction paragraph lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore east dolore magna.",
}

Teaser.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  excerpt: PropTypes.string,
  img: PropTypes.object,
}
